.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sf-wrapper {
	margin-top: 10px;
	max-width: 855px;
	min-height: 800px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 100px;
	padding-bottom: 50px;
  background: white;
	border: 1px solid #d9d9d9;
}
.sf-wrapper {
	/* border: none !important; */
	margin-bottom: 10px;
	padding-bottom: 0px;
}
.sf-content-holder {
	margin-left: 30px;
	margin-right: 30px;
}
.sf-bottom-margin{
  padding-bottom: 20px;
}
.sf-summary-holder:before {
	content:"";
    display:block;
    clear:both;
}
body{
  font-family: gothic;
  font-size: 14px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
