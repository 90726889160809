.sf-ref-holder {
	border-top: 2px solid gray;
	margin-top: 40px;
}
.sf-ref-holder:after {
	content:"";
    display:block;
    clear:both;

}
.sf-main-left {
	float: left;
	width: 50%;
}
.sf-main-right {
	float: left;
	width: 50%;
}
.sf-main-box-holder-left {
	margin-right: 10px;

}
.sf-main-box-holder-right {
	margin-left: 10px;

}
.sf-project-holder:after {
    content:"";
    display:block;
    clear:both;
}

@media (max-width: 767px) {
    .no-pjs-render .sf-top-social {
        position: absolute;
        right: 5px;
        top:5px;
    }
    .no-pjs-render .sf-top-social .fa {
        color: white;
        font-size: 15px;
        margin-left: 5px;
    }
    .no-pjs-render .sf-main-left {
        width: 100%;
    }
    
    .no-pjs-render .sf-main-right {
        width: 100%;
    }
    .no-pjs-render .sf-main-box-holder-left {
        margin-right: 0px;
    }
    
    .no-pjs-render .sf-main-box-holder-right {
        margin-left: 0px;
    }
    
    .no-pjs-render .sf-ref-box {
        float: left;
        width: 50%;
        margin-bottom: 20px;
    }
    .no-pjs-render .sf-name {
        font-size: 20px;
    }
    .no-pjs-render .sf-title {
        font-size: 14px;
    }.no-pjs-render .sf-contact-heading {
        font-size: 14px;
    }
    .no-pjs-render .sf-main-title {
        font-size: 20px;
    }
    .no-pjs-render .sf-fa-holder {
        width: 40px;
        height: 40px;
        padding-top: 10px;
        margin-right: 5px;
    }
    .no-pjs-render .fa-main-title {
        font-size: 20px;
    }
    }