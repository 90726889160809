.sf-holder {
	width: 100%;
}
.sf-top-first {
	text-align: center;
	background: #1D4982;
	width: 100%;
	height: 250px;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: 20px;
	position: relative;
}
.div-with-bg{
    background-image: '../assets/bg.jpg';
}
.sf-top-social {
	position: absolute;
	right: 20px;
}
.cvm-avatar {
	float: top;
	background: white !important;
	margin-top: 51px;
	width: 180px;
	height: 180px;
	text-align: center;
	border-radius: 100%;
	margin-left: auto;
	margin-right: auto;
}
.cvm-avatar img {
	width: 170px;
	height: 170px;
	border-radius: 100%;
	margin-top: 5px;
}
.social-icon {
	color: white;
	font-size: 25px;
	margin-left: 25px;
}
.sf-top-second {
	text-align: center;
}
.sf-name {
	font-size: 30px;
	letter-spacing: 3px;
	font-weight: 400;
	display: block;
	font-family: gothic;
}
.sf-name-white {
	font-size: 30px;
	letter-spacing: 3px;
	color: white;
	display: block;
	
}
.sf-title {
	font-size: 17px;
	font-family: gothic;
}
.sf-contact-holder-noimg {
	border-bottom: 2px solid gray;
	padding: 10px 0px;
}
.sf-contact-holder-noimg:after {
    content:"";
    display:block;
    clear:both;
}
.sf-contact-holder {
	border-top: 2px solid gray;
	border-bottom: 2px solid gray;
	padding: 10px 0px;
	margin-top: 10px;
}
.sf-contact-holder:after {
    content:"";
    display:block;
    clear:both;
}
.sf-contact {
	float: left;
	width: 33%;
	text-align: center;
	margin-bottom: 10px;
}
.namn {
	font-size: 11px;
	color: #1D4982;
	display: block;
	font-family: gothic-bold
}
.namn-bottom-pad{
	padding-bottom: 10px;
}
.text-description{
	word-wrap: break-word;
}