.small-segment-skill-holder {
	width: 100%;
	margin-top: 5px;
	padding-left: 5px;
}
.sf-content-holder:before {
	content:"";
    display:block;
    clear:both;
}
.sf-main-holder:after {
	content:"";
    display:block;
    clear:both;
}
.sf-half-left {
	float: left;
	width: 50%;
}
.sf-half-right {
	float: left;
	width: 50%;
}
.right-align {
	text-align: right;
}
.circle{
    background: #1D4982 !important;
	display: inline-block;
	margin-right: 4px;
	margin-left: 4px;
    width: 15.5px;
    height: 15.5px;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
	background: #1D4982;
}
.circle-nofill{
	display: inline-block;
	margin-right: 4px;
	margin-left: 4px;
    width: 15.5px;
    height: 15.5px;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    background: #cfd7d3 !important;
}