.sf-heading-holder {
	margin-top: 25px;
	margin-bottom: 15px;
}
.sf-fa-holder {
	background: #1D4982;
	padding-top: 10px;
	border-radius: 100%;
	margin-right: 10px;
	width: 40px;
	height: 40px;
	text-align: center;
	display: inline-block;
}
.tt-title-icon {
	font-size: 20px;
    font-weight: 600;
	color: white;
}
.sf-main-title {
	font-size: 20px;
	font-weight: 700;
    margin-left: 10px;
    letter-spacing: 1px;
    font-family: gothic-bold;
}