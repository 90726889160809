.small-segment-holder {
	width: 100%;
	padding-left: 5px;
	margin-bottom: 15px;
}
.datum {
	float: right;
}
.namn {
	font-size: 15px;
	color: #1D4982;
	display: block;
	font-weight: 500;
    margin-bottom: 5px;
    font-family: gothic;
}
.p-padding {
    margin-bottom: 7px;
    margin-top: 7px;
}